<template>
  <div class="page-accounts">
    <h2 class="p-title">账号列表</h2>
    <yos-tab-nav :list="tabNav" />
    <div class="p-card fix">
      <yos-button class="yos-button-primary" to="/setting/accounts/new">创建账号</yos-button>
      <form class="p-search-bar" @submit.prevent="onSubmit">
        <span>人员：</span>
        <yos-input
          v-model.trim="keyword"
          placeholder="搜索人员名称"
        />
        <yos-button type="submit" class="yos-button-primary">搜索</yos-button>
        <yos-button type="reset" @click="onReset">重置</yos-button>
      </form>
      <table
        cellspacing="0"
        class="m-table"
      >
        <thead>
          <tr>
            <th>姓名</th>
            <th>手机号</th>
            <th>职责</th>
            <th>管理公众号数量</th>
            <th>状态</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in users" :key="item.id">
            <td>{{item.name}}</td>
            <td>{{item.mobile}}</td>
            <td>
              <span v-for="(role, idx) in item.roles" :key="role.id" :class="addClass([!role.status, 'del'])">{{idx === 0 ? '' : ','}}{{role.name}}</span>
            </td>
            <td>0个</td>
            <td :class="addClass([item.status !== 1, 'red'])">{{item.status === 1 ? '正常' : '禁用'}}</td>
            <td>
              <router-link class="option" :to="`/setting/accounts/${item.id}`">编辑</router-link>
              <span
                class="option"
                v-if="item.name !== 'admin'"
                @click="onUpdateUser(item.id, item.status === 0 ? 1 : 0)"
              >
                {{item.status === 1 ? '禁用账号' : '恢复正常'}}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <yos-pager
        :baseUrl="baseUrl"
        :totalPage="totalPage"
        @change="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import YosPager from '../../components/other/yos-pager'
import YosTabNav from '../../components/other/tab-nav'
import { reactive,  toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { usePage, useServer, useDialog, useToast, useTabNav } from '../../hook'
import { addClass, serialize } from '../../util'
import {
  getAccounts,
  updateAccountStatus
} from '../../data'

export default {
  name: 'Accounts',
  components: {
    YosPager,
    YosTabNav
  },
  setup() {
    const toast = useToast()
    const dialog = useDialog()
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      keyword: route.query.keyword || '',
      totalPage: -1,
      users: []
    })

    const nav = [{
      name: '账号列表',
      to: '/setting/accounts'
    }, {
      name: '职责列表',
      to: '/setting/roles'
    }]

    const { page, baseUrl } = usePage('/setting/accounts', { keyword: state.keyword })

    const setAccountsParams = useServer(getAccounts, (data) => {
      state.users = data.users
      state.totalPage = data.totalPage
    })

    const setUpdateAccountStatusParams = useServer(updateAccountStatus, ({ msg }, { id, status }) => {
      toast(msg)
      state.users = state.users.map(item => {
        if (item.id === id) {
          item.status = status
        }
        return item
      })
    })

    const onUpdateUser = (id, status) => {
      const title = status !== 1 ? '禁用账号' : '启用账号'
      const content = status !== 1 ? '确认禁用该账号？/n禁用后，无法使用该账号登录后台。' : '确认启用该账号？/n启用后，可使用该账号登录后台。'
      dialog({
        title,
        content,
        onOk: () => {
          setUpdateAccountStatusParams({id, status})
        }
      })
    }

    const onSubmit = () => {
      const params = { keyword: state.keyword, page: 1 }
      router.replace(serialize(baseUrl, params))
      setAccountsParams(params)
    }

    const onReset = () => {
      state.keyword = ''
      onSubmit()
    }

    const onPageChange = (page) => {
      setAccountsParams({ keyword: state.keyword, page })
    }

    setAccountsParams({ keyword: state.keyword, page })

    return {
      tabNav: useTabNav(nav),
      ...toRefs(state),
      page,
      baseUrl,
      addClass,
      onUpdateUser,
      onSubmit,
      onReset,
      onPageChange
    }
  }
}
</script>
